import React, { useEffect, useState } from 'react';
import { Container, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, CircularProgress } from '@mui/material';
import { LightPurpleButton } from '../../../utils/buttonStyles';
import { KeyboardDoubleArrowLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { updateOrderData } from '../../../redux/userSlice.js';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable'; // Ensure you have installed jsPDF and jsPDF-autotable
import { css } from '@emotion/react'; // For styling spinner

const loaderStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
`;

const CheckoutAftermath = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentUser, orderData } = useSelector((state) => state.user);
    const [customerData, setCustomerData] = useState({});
    const [loading, setLoading] = useState(true);

    const customerID = currentUser?._id;
        const shippingFee = 100;


        useEffect(() => {
            if (!customerID) return;
          
            // Fetch customer data
            axios.get('https://backend.alamkitab.com/getAllCustomers')
              .then((response) => {
                console.log('Customer API Response:', response.data); // Log the response
                const customer = response.data.find(cust => cust.customerID === customerID);
                console.log('Matched Customer:', customer); // Log the matched customer
                setCustomerData(customer);
              })
              .catch((error) => {
                console.error('Error fetching customer data:', error);
              });
          
            // Polling setup for order data
            const fetchOrderData = () => {
              axios.get(`https://backend.alamkitab.com/${customerID}/getOrderedProductsByCustomer/`)
                .then((response) => {
                  const orders = response.data;
                  if (Array.isArray(orders)) {
                    const sortedOrders = orders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    const mostRecentOrder = sortedOrders[0];
                    dispatch(updateOrderData(mostRecentOrder));
                    setLoading(false); // Stop loading when data is fetched
                  }
                })
                .catch((error) => {
                  console.error('Error fetching order data:', error);
                });
            };
          
            fetchOrderData(); // Initial fetch
            const intervalId = setInterval(fetchOrderData, 1000); // Poll every 3 seconds
          
            return () => clearInterval(intervalId); // Clean up the interval on component unmount
          }, [customerID, dispatch]);
          

            // Calculate the total price of products
            const totalPrice = orderData?.orderedProducts?.reduce((acc, product) => acc + (product.price?.cost * product.quantity), 0) || 0;
            const totalWithShipping = totalPrice + shippingFee;

            const downloadPDF = () => {
                const doc = new jsPDF();
            
                // Add title
                doc.setFont('Helvetica', 'bold');
                doc.setFontSize(22);
                doc.text('Alamkitab', 105, 20, { align: 'center' });
            
                // Add subtitle
                doc.setFontSize(18);
                doc.text('Order Invoice', 105, 30, { align: 'center' });
            
                // Add customer and order details
                doc.setFont('Helvetica', 'normal');
                doc.setFontSize(12);
                doc.text(`Order ID: ${orderData.orderNumber}`, 20, 50);
                doc.text(`Order Date: ${new Date(orderData.createdAt).toLocaleDateString()}`, 20, 60);
                doc.text(`Customer Name: ${customerData?.customerName}`, 20, 70);
                doc.text(`Email: ${customerData?.customerEmail}`, 20, 80);
                doc.text(`Mobile: ${orderData.shippingData?.phoneNo}`, 20, 90);
                doc.text(`Shipping Address: ${orderData.shippingData?.address}, ${orderData.shippingData?.city}, ${orderData.shippingData?.state}, ${orderData.shippingData?.country}`, 20, 100);
            
                // Add the products table
                doc.autoTable({
                    startY: 110,
                    head: [['Book', 'Quantity', 'Price', 'Total']],
                    body: orderData.orderedProducts.map(product => [
                        product.productName,
                        product.quantity,
                        product.price?.cost,
                        product.price?.cost * product.quantity
                    ]),
                    theme: 'striped',
                    margin: { top: 10 },
                    styles: {
                        fontSize: 10,
                        cellPadding: 5,
                        overflow: 'linebreak',
                        halign: 'center'
                    },
                    headStyles: {
                        fillColor: [0, 0, 0],
                        textColor: [255, 255, 255],
                        fontStyle: 'bold'
                    }
                });
            
                // After the table, add the pricing details
                let finalY = doc.lastAutoTable.finalY + 10; // Get the last Y position after the table
            
                // Add pricing info (Total without shipping, Shipping Fee, Total with shipping)
                doc.setFontSize(14);
                doc.setFont('Helvetica');
                doc.text(`Total Price (without shipping): ${totalPrice} Rupees`, 10, finalY + 10);
            
                doc.text(`Shipping Fee: ${shippingFee} Rupees`, 10, finalY + 20);
            
                doc.text(`Total Price (with shipping): ${totalWithShipping} Rupees`, 10, finalY + 30);
            
                // Add Footer
                doc.setFontSize(10);
                doc.setFont('Helvetica', 'italic');
                doc.text('Thank you for your order!', 105, doc.internal.pageSize.height - 10, { align: 'center' });
            
                // Save the PDF
                doc.save('order-details.pdf');
            };
            
    
    return (
        <React.Fragment>
            <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    {loading ? (
                        <div css={loaderStyle}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            <Typography variant="h5" gutterBottom>
                                Thank you for placing the Order!
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Order Details:
                            </Typography>
                            {orderData ? (
                                <div id="order-details">
                                    <Typography variant="body1">
                                        <strong>Order Date:</strong> {new Date(orderData.createdAt).toLocaleDateString()}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Order ID:</strong> {orderData.orderNumber}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Customer Name:</strong> {customerData?.customerName}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Email:</strong> {customerData?.customerEmail}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Mobile:</strong> {orderData.shippingData?.phoneNo}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Shipping Address:</strong> {`${orderData.shippingData?.address}, ${orderData.shippingData?.city}, ${orderData.shippingData?.state}, ${orderData.shippingData?.country}`}
                                    </Typography>

                                    {/* Order Items Table */}
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><strong>Book</strong></TableCell>
                                                    <TableCell><strong>Quantity</strong></TableCell>
                                                    <TableCell><strong>Price</strong></TableCell>
                                                    <TableCell><strong>Total</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {orderData.orderedProducts && orderData.orderedProducts.map((product) => (
                                                    <TableRow key={product._id}>
                                                        <TableCell>{product.productName}</TableCell>
                                                        <TableCell>{product.quantity}</TableCell>
                                                        <TableCell>{product.price?.cost}</TableCell>
                                                        <TableCell>{product.price?.cost * product.quantity}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
                                        <strong>Mode of Payment:</strong> {orderData.paymentInfo?.method || 'Cash on Delivery'}
                                    </Typography>

                                    <Typography variant="body1" gutterBottom>
                                        <strong>Estimated delivery date:</strong> 4-5 days
                                    </Typography>
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        <strong>Total Price (without shipping):</strong> {totalPrice} Rupees
                                    </Typography>
                                    <Typography variant="body1" >
                                        <strong>Shipping Fee:</strong> {shippingFee} Rupees
                                    </Typography>
                                    <Typography variant="body1" >
                                        <strong>Total Price (with shipping):</strong> {totalWithShipping} Rupees
                                    </Typography>
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        <strong>Contact us:</strong> contactus@alamkitab.com
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>WhatsApp us:</strong> +92 332 2440544
                                    </Typography>
                                </div>
                            ) : (
                                <Typography variant="body1">No order details available.</Typography>
                            )}

                            <Button variant="contained" color="primary" onClick={downloadPDF} sx={{ mt: 2 }}>
                                Download PDF
                            </Button>
<br />
                            <LightPurpleButton sx={{ mt: 5 }} onClick={() => navigate('/')}>
                                <KeyboardDoubleArrowLeft /> Back to Home
                            </LightPurpleButton>
                        </>
                    )}
                </Paper>
            </Container>
        </React.Fragment>
    );
};

export default CheckoutAftermath;
