import React, { useState } from 'react';
import { Box, Container, Grid, Pagination } from '@mui/material';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../redux/userSlice';
import { BasicButton } from '../utils/buttonStyles';
import '../pages/slideproduct.css'
import image from '../assets/images.png'
import { Link, useNavigate } from 'react-router-dom';
import Popup from './Popup';
import { addStuff } from '../redux/userHandle';
import ProductsMenu from '../pages/customer/components/ProductsMenu';
import Search from '../pages/customer/components/Search';

const Products = ({ productData }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const itemsPerPage = 20;
  

  const { currentRole, responseSearch } = useSelector(state => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = productData.slice(indexOfFirstItem, indexOfLastItem);

  const handleAddToCart = (event, product) => {
    event.stopPropagation();
    dispatch(addToCart(product));
  };

  const handleUpload = (event, product) => {
    event.stopPropagation();
    console.log(product);
    dispatch(addStuff("ProductCreate", product));
  };

  const messageHandler = (event) => {
    event.stopPropagation();
    setMessage("You have to login or register first")
    setShowPopup(true)
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  if (responseSearch) {
    return (
    <>
        <BannerBox>
            <h1 className='h1tag'>Search the books you want</h1><br />
            <Box sx={{ flexGrow: 1, display: { md: 'flex' }, }}>
            <Search />
            <ProductsMenu dropName="Explore Subjects" />
            </Box><br /><br />
        </BannerBox>
        <h1 className='h11tag'>Sorry... this book is not currently available</h1>
        {/* <p className='p11tag'><Link to={'/'}>Goto Home Page</Link></p> */}
                
    </>)
  }

  return (
    <>
      <BannerBox>
          <h1 className='h1tag'>Search the books you want</h1><br />
          <Box sx={{ flexGrow: 1, display: { md: 'flex' }, }}>
          <Search />
          <ProductsMenu dropName="Explore Subjects" />
          </Box><br /><br />
      </BannerBox>
      <ProductGrid  spacing={3}>
        {currentItems.map((data, index) => (
          <Grid item xs={1} sm={1} md={1}
            key={index}
            // onClick={() => navigate("/product/view/" + data._id)}
            sx={{ cursor: "pointer" }}
          >
            <ProductContainer>
              {/* <ProductImage src={data.productImage} />
              <ProductName>{data.productName}</ProductName>
              <PriceMrp>{data.price.mrp}</PriceMrp>
              <PriceCost>Rs{data.price.cost}</PriceCost>
              <PriceDiscount>{data.price.discountPercent}% off</PriceDiscount> */}

              <div className="container_h">
                  <div className="card_h">
                      <div className='child1_h'>
                          <img src={image} className="img-section_h" />
                      </div>
                      <div className='child2_h'>
                          <div className="title_h">{data.productName}</div>
                          {/* <div className="content_h"style={{ color: '#525050', textDecoration: "line-through" }}>{product.price.mrp}</div> */}
                          <div className="content_h">Rs {data.price.cost}</div>
                          <p className="additional-text_h">Author: {data.tagline}</p>
                            <AddToCart>
                              {currentRole === "Customer" &&
                                <>
                                  <BasicButton
                                    onClick={(event) => handleAddToCart(event, data)}
                                  >
                                    Add To Cart
                                  </BasicButton>
                                </>
                              }
                              {currentRole === "Shopcart" &&
                                <>
                                  <BasicButton
                                    onClick={(event) => handleUpload(event, data)}
                                  >
                                    Upload
                                  </BasicButton>
                                </>
                              }
                              {currentRole === null &&
                                <>
                                  <BasicButton
                                    onClick={messageHandler}
                                  >
                                    Add To Cart
                                  </BasicButton>
                                </>
                              }

                            </AddToCart>
                      </div>
                  </div>
              </div>
            </ProductContainer>
          </Grid>
        ))}
      </ProductGrid>

      <Container sx={{ mt: 10, mb: 10, display: "flex", justifyContent: 'center', alignItems: "center" }}>
        <Pagination
          count={Math.ceil(productData.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="secondary"
        />
      </Container>

      <Popup message={message} setShowPopup={setShowPopup} showPopup={showPopup} />
    </>
  )
};

export default Products;
const BannerBox = styled(Box)`
  padding: 20px 10px;
  background: darkgreen;
`;

const ProductContainer = styled.div`
  display: block;
  padding: 16px;
`;

const ProductGrid = styled(Grid)`
  display: block;
`;

const ProductImage = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 8px;
`;

const ProductName = styled.p`
  font-weight: bold;
  text-align: center;
`;

const PriceMrp = styled.p`
  margin-top: 8px;
  text-align: center;
  text-decoration: line-through;
  color: #525050;
`;

const PriceCost = styled.h3`
  margin-top: 8px;
  text-align: center;
`;

const PriceDiscount = styled.p`
  margin-top: 8px;
  text-align: center;
  color: darkgreen;
`;

const AddToCart = styled.div`
  margin-top: 16px;
`;