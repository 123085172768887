import React from 'react'
import "./footer.css"
import 'boxicons'

import { Link, NavLink } from 'react-router-dom'

const Footer = () => {
return(
    <>
        <footer className="footer-distributed footer">

            <div className="footer-left">
                <h3>Alamkitab</h3>
                <br />

                <p className="footer-company-name">Copyright © 2024 <strong>Alamkitab</strong> All rights reserved</p>
            </div>

            <div className="footer-center">
                <div>
                    <i className="fa fa-map-marker"></i>
                    <p><span>Karachi</span>
                        Pakistan</p>
                </div>

                <div>
                    <i className="fa fa-phone"></i>
                    <p><a href="tel:+923322440544" target="_blank">+92 332 2440544</a></p>
                </div>
                <div>
                    <i className="fa fa-envelope"></i>
                    <p><a href="mailto:beedabluu@gmail.com">contactus@alamkitab.com</a></p>
                </div>
            </div>
            <div className="footer-right">
                <p className="footer-company-about">
                    <span>About the company</span>
                    <strong>Welcome to Alamkitab,</strong> Explore books that will touch your heart & challenge your mind.</p>
            </div>
        </footer>
    </>
)
}

export default Footer